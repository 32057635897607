var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"table-normal animate__animated animate__fadeIn animate__slow",staticStyle:{"width":"100%"},attrs:{"data":_vm.dataAppeove,"border":"","element-loading-text":"กำลังโหลดข้อมูล...","element-loading-spinner":"el-icon-loading"}},[_c('template',{slot:"empty"},[_c('p',{staticClass:"font-30 font-weight-500"},[_vm._v("ไม่พบข้อมูลที่คุณค้นหา")])]),_c('el-table-column',{attrs:{"prop":"leadId","label":"leadRefId","width":"85px"}}),_c('el-table-column',{attrs:{"prop":"customerId","label":"custCode","width":"85px"}}),_c('el-table-column',{attrs:{"prop":"juristicId","label":"juristicOrPersonId","width":"150px"}}),_c('el-table-column',{attrs:{"prop":"customerNameTH","label":"custNameTH","min-width":"150px"}}),_c('el-table-column',{attrs:{"prop":"customerNameEN","label":"custNameEN","min-width":"150px"}}),_c('el-table-column',{attrs:{"label":"nplStatus","prop":"debtorStatus","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.debtorStatus == 'no' ||
            scope.row.debtorStatus == 'No' ||
            scope.row.debtorStatus == '0'
          )?_c('span',[_vm._v("0")]):(
            scope.row.debtorStatus == 'yes' ||
            scope.row.debtorStatus == 'Yes' ||
            scope.row.debtorStatus == '1'
          )?_c('span',[_vm._v("1")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"hasLoanPerfStatus","width":"138px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.creditStatus == 'no' ||
            scope.row.creditStatus == 'No' ||
            scope.row.creditStatus == '0'
          )?_c('span',[_vm._v("0")]):(
            scope.row.creditStatus == 'yes' ||
            scope.row.creditStatus == 'Yes' ||
            scope.row.creditStatus == '1'
          )?_c('span',[_vm._v("1")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"hasInsurePerfStatus","width":"145px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.insuranceStatus == 'no' ||
            scope.row.insuranceStatus == 'No'
          )?_c('span',[_vm._v("0")]):(
            scope.row.insuranceStatus == 'yes' ||
            scope.row.insuranceStatus == 'Yes'
          )?_c('span',[_vm._v("1")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"ratingYear","label":"ratingYear","width":"85px"}}),_c('el-table-column',{attrs:{"prop":"creditRating","label":"ratingComposite","width":"125px"}}),_c('el-table-column',{attrs:{"prop":"eximCreditratingId","label":"exim_creditratingId","min-width":"150px"}}),_c('el-table-column',{attrs:{"prop":"custGuid","label":"custGuid","min-width":"150px"}})],2),_c('div',{staticClass:"text-center mg-t-5"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 30, 50, 100, 500],"page-size":_vm.limit,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.totalNow},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.changePage,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }