<template>
  <div>
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow"
      :data="dataMajorMinor"
      border
      style="width: 100%"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
      </template>
      <el-table-column label="prodTypeCode" width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.prodTypeCode != null">{{
            scope.row.prodTypeCode
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="prodCode" width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.prodCode != null">{{
            scope.row.prodCode
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="prodName" min-width="220">
        <template slot-scope="scope">
          <span v-if="scope.row.prodName != null">{{
            scope.row.prodName
          }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="major" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.major != null">{{ scope.row.major }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
       <el-table-column label="minor" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.minor != null">{{ scope.row.minor }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="authen" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.authen != null">{{ scope.row.authen }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
       <el-table-column label="conversFactor" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.conversFactor != null">{{ scope.row.conversFactor }}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
        <el-table-column label="Created On" width="200">
        <template slot-scope="scope">
          <span v-if="scope.row.createdAt != null">{{ scope.row.createdAt |formatDateImport}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="text-center mg-t-5" v-if="totalNow > 10">
       <el-pagination
        @size-change="handleSizeChange"
        @current-change="changePage"
        :current-page.sync="page"
        :page-sizes="[10, 30, 50, 100, 500]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNow"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataMajorMinor: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    totalNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    limit: {
      type: Number,
      default() {
        return 10;
      },
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.page = this.pageNow;
  },

  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    handleSizeChange(page) {
      this.$emit("handleSizeChange", page);
    },
  },
};
</script>

<style></style>
