<template>
  <DefultTempalte
    v-loading.lock="loadingSubmit"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <h1>Import/Export Data</h1>
      <el-form ref="form" :model="form" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Data">
              <el-radio-group
                v-model="form.type"
                @change="form.type == 2 ? (form.from = null) : null"
              >
                <el-radio :label="1">Import</el-radio>
                <el-radio :label="2">Export</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <!-- <el-form-item> -->
            <el-radio-group
              v-model="form.from"
              @change="clickForm"
              v-if="form.type == 2"
            >
              <el-form-item label="Type" v-if="form.type != null">
                <el-radio label="CRM">LOS / CRM</el-radio>
                <el-radio label="CH">Channel Integration</el-radio>
              </el-form-item>
            </el-radio-group>
            <el-select
              id="formDataInsuranceProductId"
              v-model="form.from"
              filterable
              @change="clickForm"
              :placeholder="
                form.type == 2
                  ? 'เลือกประเภทข้อมูลที่ Export'
                  : 'เลือกประเภทข้อมูลที่ Import'
              "
              class="w-100"
              v-else
            >
              <el-option
                v-for="item in optionsInsurance"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- </el-form-item> -->
            <!-- <el-radio label="LOS">LOS</el-radio> -->
            <!-- <el-form-item label="Type" v-if="form.type != null">
              <el-radio-group v-model="form.from" @change="clickForm">
                <el-radio label="CRM">LOS / CRM</el-radio>
                <el-radio v-if="form.type == 1" label="กรมศุลกากร"
                  >กรมศุลกากร</el-radio
                >
                <el-radio
                  v-if="form.type == 1"
                  label="ข้อมูลเครดิตจาก เครดิตบูโร (NCB)"
                  disabled
                  >ข้อมูลเครดิตจาก เครดิตบูโร (NCB)</el-radio
                >
                <el-radio
                  v-if="form.type == 1"
                  label="ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก. ธนารักษ์พัฒนาสินทรัพย์"
                  disabled
                  >ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก.
                  ธนารักษ์พัฒนาสินทรัพย์</el-radio
                >
                <el-radio
                  v-if="form.type == 1"
                  label="ข้อมูลเครดิตจาก ธสน. (EXIM BANK)"
                  disabled
                  >ข้อมูลเครดิตจาก ธสน. (EXIM BANK)</el-radio
                >
              </el-radio-group>
            </el-form-item> -->
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="box-card mg-t-5">
      <div v-if="form.type == 1 && form.from !== null">
        <h1>
          นำเข้าข้อมูล
          <span v-if="form.from == 'CRM'">LOS / CRM </span>
          <!-- major-minor -->
          <span v-else-if="form.from == 'major-minor'"
            >สินเชื่อ (MAJOR-MINOR)</span
          >
          <span v-else-if="form.from == 'product-program'"
            >สินเชื่อ (Product Program NAME)</span
          >
          <span v-else-if="form.from == 'insurance-product'"
            >ประกัน (Insurance Product ID)</span
          >
          <span v-else>{{ form.from }}</span>
        </h1>
        <div>
          <p class="font-20 mg-b-6">
            Import CSV, Excel File
            <span
              class="mg-l-5"
              v-if="
                form.from != 'ข้อมูลเครดิตจาก เครดิตบูโร (NCB)' &&
                form.from !=
                  'ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก. ธนารักษ์พัฒนาสินทรัพย์'
              "
            >
              <el-tooltip
                placement="bottom-start"
                style="font-family: 'Font Awesome 5 Free' !important"
              >
                <div slot="content">
                  <span>
                    การตั้งชื่อไฟล์ในการ Import <br /><br />
                    <span v-if="form.from == 'CRM'">
                      1. ข้อมูลลูกค้า ตั้งชื่อไฟล์ : CUSTOMER.csv
                      <br />
                      2. ประวัติการปิด Lead ตั้งชื่อไฟล์ : LEAD.csv <br />
                      3. ข้อมูลการขออนุมัติ ตั้งชื่อไฟล์ : APPROVE.csv
                      <br />
                      4. ข้อมูล Pipeline รับประกัน ตั้งชื่อไฟล์ : PIPELINE.csv
                      <br />
                      5. ข้อมูล Call Memo ตั้งชื่อไฟล์ : CALLMEMO.csv
                      <br />
                    </span>
                    <span v-else-if="form.from == 'กรมศุลกากร'">
                      1. ข้อมูลกรมศุลกากร ตั้งชื่อไฟล์ : customs-department.xlsx
                      <br />
                    </span>
                    <span v-else-if="form.from == 'major-minor'">
                      1. ข้อมูลสินชื่อ (MAJOR-MINOR) ตั้งชื่อไฟล์ :
                      credit-type.xlsx
                      <br />
                    </span>
                    <span v-else-if="form.from == 'product-program'">
                      1. ข้อมูลสินชื่อ (Product Program NAME) ตั้งชื่อไฟล์ :
                      product-program.xlsx
                      <br />
                    </span>
                    <span v-else-if="form.from == 'insurance-product'">
                      1. ข้อมูลประกัน (Insurance Product ID) ตั้งชื่อไฟล์ :
                      insurance-type.xlsx
                      <br />
                    </span>
                  </span>
                </div>
                <i class="fas fa-question-circle"></i>
              </el-tooltip>
              การตั้งชื่อไฟล์
            </span>
          </p>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-upload
                multiple
                action="#"
                :auto-upload="false"
                :on-change="(file, fileList) => uploadDocuments(file, fileList)"
                :on-remove="handleRemoveDocuments"
                :limit="
                  form.from == 'major-minor' ||
                  form.from == 'product-program' ||
                  form.from == 'insurance-product'
                    ? 1
                    : 5
                "
                :file-list="fileList"
                :on-exceed="handleExceed"
              >
                <el-button type="info" size="default" class="w-100"
                  >แนบไฟล์</el-button
                >
              </el-upload>
              <!-- <el-upload
                action="#"
                :auto-upload="false"
                :show-file-list="false"
              >
                <div class="is-flex">
                  <el-input v-model="form.file" placeholder="แนบไฟล์">
                  </el-input>
                  <el-button type="info" size="default" class="mg-l-1"
                    >แนบไฟล์</el-button
                  >
                </div>
              </el-upload> -->
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mg-t-3">
            <el-col :span="12" :offset="0" class="text-right">
              <a @click="resetForm" class="font-18 cursor-pointer">ยกเลิก</a>
              <el-button
                type="primary"
                size="default"
                class="mg-l-1"
                :loading="loadingSubmit"
                @click="submitImportData"
                :disabled="form.file.length == 0"
                >นำเข้าข้อมูล</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="form.type == 2 && form.from !== null">
        <h1 v-if="form.from == 'CRM'">ส่งออกข้อมูล LOS / CRM</h1>
        <h1 v-else>ส่งออกข้อมูล Channel Integration</h1>
        <div>
          <p class="font-20">วันที่ส่งข้อมูลไปยัง CRM</p>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                class="w-100"
                range-separator="ถึง"
                start-placeholder="วันที่เริ่ม"
                end-placeholder="วันที่สิ้นสุด"
              >
              </el-date-picker>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mg-t-3">
            <el-col :span="12" :offset="0" class="text-right">
              <a @click="resetForm" class="font-18">ยกเลิก</a>
              <!-- <el-button
                type="primary"
                size="default"
                class="mg-l-1"
                :disabled="
                  form.date == null || form.date.length == 0 ? true : false
                "
                :loading="loadingSubmit"
                @click="submitExportData"
                >Export CSV
                {{ form.type == 2 ? "LOS / CRM" : form.from }}</el-button
              > -->
              <el-button
                type="primary"
                size="default"
                class="mg-l-1"
                :disabled="
                  form.date == null || form.date.length == 0 ? true : false
                "
                :loading="loadingSubmit"
                @click="submitExportData"
                >Export CSV {{ textBtn }}</el-button
              >
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- table data -->
      <div
        v-if="
          form.type == 1 &&
          form.from !== null &&
          form.from != 'ข้อมูลเครดิตจาก เครดิตบูโร (NCB)' &&
          form.from !=
            'ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก. ธนารักษ์พัฒนาสินทรัพย์'
        "
        class="tab-dashboard size-tab el-tabs el-tabs--card el-tabs--top"
      >
        <div class="is-flex js-between ai-center">
          <h1>
            แสดงข้อมูล
            <span v-if="form.from == 'CRM'">LOS / CRM</span>
            <span v-else-if="form.from == 'major-minor'"
              >สินเชื่อ (MAJOR-MINOR)</span
            >
            <span v-else-if="form.from == 'product-program'"
              >สินเชื่อ (Product Program NAME)</span
            >
            <span v-else-if="form.from == 'insurance-product'"
              >ประกัน (Insurance Product ID)</span
            >
            <!-- <span v-else-if="form.from == 'insurance-product'">ประกัน (Insurance Product ID)</span> -->
            <!-- {{ form.from == "CRM" ? "LOS / CRM" : form.from }} -->
          </h1>
          <el-button
            type="primary"
            size="default"
            @click="downloadTemplate"
            class="default-download"
            :class="
              form.from == 'CRM' || form.from === 'กรมศุลกากร' ? 'search' : ''
            "
            >Download template</el-button
          >
        </div>
        <div>
          <el-form :model="formdata" class="demo-form-inline">
            <el-row :gutter="20">
              <el-col :span="24">
                <el-row :gutter="20">
                  <el-col :span="6" :xs="24" v-if="form.from == 'CRM'"
                    ><el-form-item label="รหัส  Lead">
                      <el-input
                        @input="searchData"
                        v-model="formdata.lead"
                        placeholder="กรอกรหัส  Lead"
                      ></el-input> </el-form-item
                  ></el-col>
                  <el-col
                    :span="6"
                    :xs="24"
                    v-if="form.from == 'CRM' || form.from === 'กรมศุลกากร'"
                  >
                    <el-form-item label="เลขนิติบุคคล">
                      <el-input
                        @input="searchData"
                        v-model="formdata.juristicId"
                        placeholder="กรอกเลขนิติบุคคล"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6" :xs="24" v-if="form.from === 'กรมศุลกากร'"
                    ><el-form-item label="ผู้ประกอบการ">
                      <el-input
                        @input="searchData"
                        v-model="formdata.companyName"
                        placeholder="กรอกชื่อผู้ประกอบการ"
                      ></el-input> </el-form-item
                  ></el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-if="form.from == 'CRM' && !loadingSubmit">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane name="customer" label="CUSTOMER">
              <p class="text-right mg-b-7 color-75" v-if="checkStatusNow()">
                <span v-if="fetchUpload.status == 1">
                  <i class="el-icon-loading mg-r-7"></i>
                  กำลัง Upload File</span
                >
                <span v-else>
                  อัปเดตเมื่อ {{ fetchUpload.updatedAt | formatDateReport }}
                </span>
              </p>
              <TableCustomers
                :dataAppeove="dataAppeove"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
              />
            </el-tab-pane>
            <el-tab-pane name="lead" label="LEAD">
              <p class="text-right mg-b-7 color-75" v-if="checkStatusNow()">
                <span v-if="fetchUpload.status == 1">
                  <i class="el-icon-loading mg-r-7"></i>
                  กำลัง Upload File</span
                >
                <span v-else>
                  อัปเดตเมื่อ {{ fetchUpload.updatedAt | formatDateReport }}
                </span>
              </p>
              <TableLead
                :dataAppeove="dataAppeove"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
            /></el-tab-pane>
            <el-tab-pane name="approve" label="APPROVE">
              <p class="text-right mg-b-7 color-75" v-if="checkStatusNow()">
                <span v-if="fetchUpload.status == 1">
                  <i class="el-icon-loading mg-r-7"></i>
                  กำลัง Upload File</span
                >
                <span v-else>
                  อัปเดตเมื่อ {{ fetchUpload.updatedAt | formatDateReport }}
                </span>
              </p>
              <TableApprove
                :dataAppeove="dataAppeove"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
            /></el-tab-pane>

            <el-tab-pane name="pipeline" label="PIPELINE ">
              <p class="text-right mg-b-7 color-75" v-if="checkStatusNow()">
                <span v-if="fetchUpload.status == 1">
                  <i class="el-icon-loading mg-r-7"></i>
                  กำลัง Upload File</span
                >
                <span v-else>
                  อัปเดตเมื่อ {{ fetchUpload.updatedAt | formatDateReport }}
                </span>
              </p>
              <TablePipeLine
                :dataAppeove="dataAppeove"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
            /></el-tab-pane>
            <el-tab-pane name="callMemo" label="CALLMEMO">
              <p class="text-right mg-b-7 color-75" v-if="checkStatusNow()">
                <span v-if="fetchUpload.status == 1">
                  <i class="el-icon-loading mg-r-7"></i>
                  กำลัง Upload File</span
                >
                <span v-else>
                  อัปเดตเมื่อ {{ fetchUpload.updatedAt | formatDateReport }}
                </span>
              </p>
              <TableCallMemo
                :dataAppeove="dataAppeove"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
            /></el-tab-pane>
          </el-tabs>
        </div>
        <div
          v-else-if="form.from == 'กรมศุลกากร' && !loadingSubmit"
          class="mg-t-1"
        >
          <el-tabs v-model="form.from" type="card">
            <el-tab-pane name="กรมศุลกากร" label="ข้อมูลกรมศุลกากร">
              <TableImportCustomers
                :dataDepartment="dataDepartment"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
              />
            </el-tab-pane>
          </el-tabs>
          <!-- <TableImportCustomers
            :dataDepartment="dataDepartment"
            :loading="loading"
            :totalNow="totalNow"
            :pageNow="pageNow"
            @changePage="changePage"
          /> -->
        </div>
        <div v-else-if="form.from == 'major-minor' && !loadingSubmit">
          <el-tabs v-model="form.from" type="card">
            <el-tab-pane name="major-minor" label="ข้อมูลสินชื่อ (MAJOR-MINOR)">
              <TableMajorMinor
                :dataMajorMinor="dataMajorMinor"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-else-if="form.from == 'product-program' && !loadingSubmit">
          <el-tabs v-model="form.from" type="card">
            <el-tab-pane
              name="product-program"
              label="ข้อมูลสินเชื่อ (Product Program NAME)"
            >
              <TableProductProgram
                :dataProductProgram="dataProductProgram"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div v-else-if="form.from == 'insurance-product' && !loadingSubmit">
          <el-tabs v-model="form.from" type="card">
            <el-tab-pane
              name="insurance-product"
              label="ข้อมูลประกัน (Insurance Product ID)"
            >
              <TableInsuranceProduct
                :dataInsurance="dataInsurance"
                :loading="loading"
                :totalNow="totalNow"
                :pageNow="pageNow"
                @changePage="changePage"
                :limit="limit"
                @handleSizeChange="handleSizeChange"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </DefultTempalte>
</template>
<script>
import TableApprove from "@/components/table/TableApprove";
import TableCallMemo from "@/components/table/TableCallMemo";
import TableCustomers from "@/components/table/TableCustomers";
import TableLead from "@/components/table/TableLead";
import TablePipeLine from "@/components/table/TablePipeLine";
import TableImportCustomers from "@/components/table/TableImportCustomers";
import TableMajorMinor from "@/components/table/TableMajorMinor";
import TableProductProgram from "@/components/table/TableProductProgram";
import TableInsuranceProduct from "@/components/table/TableInsuranceProduct";

import DefultTempalte from "@/template/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { HTTPUPLOAD } from "@/service/axios";

import { mapState } from "vuex";

export default {
  components: {
    DefultTempalte,
    TableApprove,
    TableCallMemo,
    TableCustomers,
    TableLead,
    TablePipeLine,
    TableImportCustomers,
    TableMajorMinor,
    TableProductProgram,
    TableInsuranceProduct,
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
    textBtn() {
      if (this.form.from == "CRM" && this.form.type == 2) {
        return "LOS / CRM";
      } else if (this.form.from == "CH" && this.form.type == 2) {
        return "Channel Integration";
      } else {
        return this.form.from;
      }
    },
  },
  data() {
    return {
      fetchUpload: null,
      getUpload: [],
      skip: 0,
      limit: 10,
      totalNow: 10,
      pageNow: 1,
      loading: true,
      debounce: null,
      dataAppeove: [],
      dataDepartment: [],
      formdata: {
        juristicId: "",
        lead: "",
        companyName: "",
      },
      activeName: "customer",
      urlBase: process.env.VUE_APP_BASE_URL,
      loadingSubmit: false,
      fileList: [],
      form: {
        type: null,
        from: null,
        file: [],
        date: [],
      },
      optionsInsurance: [
        {
          id: "CRM",
          label: "LOS / CRM",
        },
        {
          id: "กรมศุลกากร",
          label: "กรมศุลกากร",
        },
        {
          id: "ข้อมูลเครดิตจาก เครดิตบูโร (NCB)",
          label: "ข้อมูลเครดิตจาก เครดิตบูโร (NCB)",
        },
        {
          id: "ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก. ธนารักษ์พัฒนาสินทรัพย์",
          label:
            "ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก. ธนารักษ์พัฒนาสินทรัพย์",
        },
        // {
        //   id: "ข้อมูลเครดิตจาก ธสน. (EXIM BANK)",
        //   label: "ข้อมูลเครดิตจาก ธสน. (EXIM BANK)",
        // },
        // {
        //   id: "major-minor",
        //   label: "สินเชื่อ (MAJOR-MINOR)",
        // },
        {
          id: "product-program",
          label: "สินเชื่อ (Product Program NAME)",
        },
        {
          id: "insurance-product",
          label: "ประกัน (Insurance Product ID)",
        },
      ],
      dataMajorMinor: [],
      dataProductProgram: [],
      dataInsurance: [],
    };
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "import/export");
    this.getStatusUpload();
  },
  methods: {
    getStatusUpload() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`import/status/upload`)
        .then((res) => {
          if (res.data.success) {
            this.getUpload = res.data.obj;
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    checkStatusNow() {
      let result = null;
      // if (this.loading) {
      // if (this.activeName == "customer") {
      //   //
      // } else if (this.activeName == "lead") {
      //   //
      // } else if (this.activeName == "approve") {

      // } else if (this.activeName == "pipeline") {
      //   //
      // } else if (this.activeName == "callMemo") {
      //   //
      // }
      let findStatus = this.getUpload.find(
        (row) => row.typeUpload == this.activeName.toUpperCase()
      );
      if (typeof findStatus != "undefined") {
        this.fetchUpload = findStatus;
        result = true;
      } else {
        result = false;
      }

      return result;
      // }
    },
    clickForm() {
      this.pageNow = 1;
      this.limit = 10;

      if (this.form.from === "CRM") {
        this.skip = 0;
        this.getDataCrmApprove();
      } else if (this.form.from === "กรมศุลกากร") {
        this.skip = 0;
        this.getDataDepartment();
      } else if (this.form.from === "major-minor") {
        this.skip = 0;
        this.getDataMajorMinor();
      } else if (this.form.from === "product-program") {
        this.skip = 0;
        this.getDataProductProgram();
      } else if (this.form.from === "insurance-product") {
        this.skip = 0;
        this.getDataInsurance();
      }
    },
    searchData() {
      this.pageNow = 1;
      this.limit = 10;
      this.skip = 0;
      this.loading = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (this.form.from === "CRM") {
          if (this.activeName == "approve") {
            this.getDataCrmApprove();
          } else if (this.activeName == "callMemo") {
            this.getDataCrmApprove();
          } else if (this.activeName === "customer") {
            this.getDataCrmApprove();
          } else if (this.activeName === "lead") {
            this.getDataCrmApprove();
          } else {
            this.getDataCrmApprove();
          }
        } else if (this.form.from === "กรมศุลกากร") {
          this.getDataDepartment();
        }
      }, 600);
    },

    getDataDepartment() {
      this.loading = true;
      let obj = {
        juristicId: this.formdata.juristicId,
        companyName: this.formdata.companyName,
        limit: this.limit,
        skip: this.skip,
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`import/data/custom/department`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataDepartment = res.data.obj;
            this.totalNow = res.data.total;
          } else {
            if (res.data.status == 422) {
              this.dataDepartment = [];
              this.totalNow = 0;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getDataMajorMinor() {
      this.loading = true;
      let obj = {
        major: "",
        minor: "",
        prodName: "",
        limit: this.limit,
        skip: this.skip,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`/import/master/data/credit`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataMajorMinor = res.data.obj;
            this.totalNow = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getDataProductProgram() {
      this.loading = true;
      let obj = {
        name: "",
        limit: this.limit,
        skip: this.skip,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`/import/master/data/product/program`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataProductProgram = res.data.obj;
            this.totalNow = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getDataInsurance() {
      this.loading = true;
      let obj = {
        name: "",
        limit: this.limit,
        skip: this.skip,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`/import/master/data/insurance`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataInsurance = res.data.obj;
            this.totalNow = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getDataCrmApprove() {
      this.loading = true;
      let obj = {
        type: this.activeName,
        juristicId: this.formdata.juristicId,
        lead: this.formdata.lead,
        limit: this.limit,
        skip: this.skip,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`import/data/CRM`, obj)
        .then((res) => {
          if (res.data.success) {
            this.dataAppeove = res.data.obj;
            this.totalNow = res.data.total;
          } else {
            if (res.data.status == 422) {
              this.dataDepartment = [];
              this.totalNow = 0;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },

    handleClick(tab, event) {
      if (tab.name === "callMemo") {
        this.limit = 10;
        this.skip = 0;
        this.getDataCrmApprove();
      } else if (tab.name === "customer") {
        this.limit = 10;
        this.skip = 0;
        this.getDataCrmApprove();
      } else if (tab.name === "lead") {
        this.limit = 10;
        this.skip = 0;
        this.getDataCrmApprove();
      } else if (tab.name === "pipeline") {
        this.limit = 10;
        this.skip = 0;
        this.getDataCrmApprove();
      } else {
        this.limit = 10;
        this.skip = 0;
        this.getDataCrmApprove();
      }
      this.getStatusUpload();
    },
    handleSizeChange(val) {
      this.pageNow = 1;
      this.limit = val;
      this.changePage(0, 0);
    },
    changePage(page, skip) {
      this.pageNow = page;
      this.loading = true;
      if (skip == 0) {
        this.skip = 0;
      } else {
        this.skip = this.limit * page - this.limit;
      }
      if (this.form.from === "CRM") {
        if (this.activeName == "approve") {
          this.getDataCrmApprove();
        } else if (this.activeName == "callMemo") {
          this.getDataCrmApprove();
        } else if (this.activeName === "customer") {
          this.getDataCrmApprove();
        } else if (this.activeName === "lead") {
          this.getDataCrmApprove();
        } else {
          this.getDataCrmApprove();
        }
      } else if (this.form.from === "กรมศุลกากร") {
        this.getDataDepartment();
      } else if (this.form.from === "major-minor") {
        this.getDataMajorMinor();
      } else if (this.form.from === "product-program") {
        this.getDataProductProgram();
      } else if (this.form.from === "insurance-product") {
        this.getDataInsurance();
      }
    },
    resetForm() {
      let obj = {
        type: null,
        from: null,
        file: [],
        date: [],
      };
      this.form = obj;
    },
    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      if (typeThis[1] == "xlsx" || typeThis[1] == "csv") {
        if (file.size < 25000000) {
          let obj = {
            name: file.name,
            file: file,
          };
          let nameTab = this.form.from.toLowerCase();
          let indexFile = fileList.findIndex((a) => a.uid == file.uid);

          if (
            (nameTab == "product-program" &&
              typeThis[0] != "product-program") ||
            (nameTab == "insurance-product" &&
              typeThis[0] != "insurance-type") ||
            (nameTab == "กรมศุลกากร" && typeThis[0] != "customs-department") ||
            (nameTab == "crm" &&
              typeThis[0] != "CUSTOMER" &&
              nameTab == "crm" &&
              typeThis[0] != "LEAD" &&
              nameTab == "crm" &&
              typeThis[0] != "APPROVE" &&
              nameTab == "crm" &&
              typeThis[0] != "PIPELINE" &&
              nameTab == "crm" &&
              typeThis[0] != "CALLMEMO")
          ) {
            this.$message({
              message: "ข้อมูลไฟล์นำเข้าไม่ถูกต้อง",
              type: "error",
              duration: 4000,
            });
            // this.form.file = [];
            fileList.splice(indexFile, 1);
          } else {
            this.form.file.push(obj);
          }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25mb",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "กรุณาเลือกไฟล์นามสกุลเป็น .CSV หรือ .XLSX เท่านั้น",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleRemoveDocuments(file) {
      let index = this.form.file.findIndex((f) => f.file.uid == file.uid);
      this.form.file.splice(index, 1);
    },
    handleExceed() {
      let nameTab = this.form.from.toLowerCase();

      if (
        nameTab == "major-minor" ||
        nameTab == "product-program" ||
        nameTab == "insurance-product"
      ) {
        this.$message.warning(`คุณสามารถแนบไฟล์ได้สูงสุด 1 ไฟล์`);
      } else {
        this.$message.warning(`คุณสามารถแนบไฟล์ได้สูงสุด 5 ไฟล์`);
      }
    },
    async submitImportData() {
      let pathAPI = "";
      let nameTab = this.form.from.toLowerCase();
      if (nameTab != "crm") {
        this.loadingSubmit = true;
      }
      if (nameTab == "crm") {
        pathAPI = "import/CRM/LOS";
      } else if (nameTab == "กรมศุลกากร") {
        pathAPI = "import/custom/department";
      } else if (
        nameTab == "major-minor" ||
        nameTab == "product-program" ||
        nameTab == "insurance-product"
      ) {
        pathAPI = "import/data/master";
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      var formData = new FormData();
      for (let index = 0; index < this.form.file.length; index++) {
        if (this.form.file[index].name != "") {
          let typeThis = this.form.file[index].file.name.split(".");
          if (typeThis[1] == "csv") {
            formData.append(
              "file",
              new File(
                [this.form.file[index].file.raw],
                this.form.file[index].file.raw.name,
                { type: "text/csv" }
              )
            );
          } else {
            formData.append("file", this.form.file[index].file.raw);
          }
        }
      }

      HTTPUPLOAD.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTPUPLOAD.post(pathAPI, formData, config)

        .then((res) => {
          if (res.data.success) {
            // if (nameTab == "major-minor") {
            //   this.getDataMajorMinor();
            // }
            if (nameTab != "crm") {
              if (this.form.from === "กรมศุลกากร") {
                this.getDataDepartment();
              } else if (this.form.from === "product-program") {
                this.getDataProductProgram();
              } else if (this.form.from === "insurance-product") {
                this.getDataInsurance();
              }
              // else if (this.form.from === "major-minor") {
              //   this.getDataMajorMinor();
              // }
              this.form.file = [];
              this.fileList = [];
              this.loadingSubmit = false;
              this.$message({
                message: "นำเข้าข้อมูลสำเร็จ!",
                type: "success",
                duration: 4000,
              });
            }
          }
          return res;
        })
        .catch((e) => {
          this.loadingSubmit = false;
          if (e.response.data.status == 400) {
            if (
              e.response.data.data == "Invalid sheet" ||
              e.response.data.data == "Invalid file" ||
              e.response.data.data == "Invalid data" ||
              e.response.data.data == "file"
            ) {
              this.$message({
                message: "ข้อมูลไฟล์นำเข้าไม่ถูกต้อง",
                type: "error",
                duration: 4000,
              });
              if (e.response.data.data != "file") {
                this.form.file = [];
                this.fileList = [];
              }
            } else {
              this.$message({
                message: "กรุณาเลือกไฟล์นามสกุลเป็น .CSV หรือ .XLSX เท่านั้น",
                type: "error",
                duration: 4000,
              });
            }
            // }
          } else if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (e.response.data.status == 502) {
            console.log("Bad GetWay");
          }
          // else {
          //   this.alertCatchError(e);
          // }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.loadingSubmit = false;
        });

      if (nameTab == "crm") {
        this.$message({
          message: "นำเข้าข้อมูลสำเร็จ!",
          type: "success",
          duration: 4000,
        });
        setTimeout(() => {
          this.getStatusUpload();
        }, 1500);
        this.form.file = [];
        this.fileList = [];
      }

      // return res;
    },
    async apiExport() {
      this.loadingSubmit = true;
      let pathAPI = "";
      ``;
      if (this.form.from.toLowerCase() == "crm") {
        pathAPI = "export/data/CRM";
      } else if (this.form.from.toLowerCase() == "ch") {
        pathAPI = "channel/integration/export";
      }

      let obj = {
        start: this.form.date[0],
        end: this.form.date[1],
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.post(pathAPI, obj)
        .then((res) => {
          setTimeout(() => {
            HTTP.delete(`file/crm/${res.data.obj}`);
          }, 1000);
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              data: e,
              success: false,
            },
          };
        })
        .finally(() => {
          this.form.file = [];
          this.fileList = [];
          this.loadingSubmit = false;
        });
      return res;
    },
    downloadTemplate() {
      this.loadingSubmit = true;
      let nameFile = "";
      if (this.form.from == "CRM") {
        nameFile = "LOSCRM.zip";
      } else if (this.form.from == "กรมศุลกากร") {
        nameFile = "customs-department.xlsx";
      } else if (this.form.from == "ข้อมูลเครดิตจาก เครดิตบูโร (NCB)") {
        nameFile = "";
      } else if (
        this.form.from ==
        "ข้อมูลเครดิตจาก สายพัฒนาธุรกิจและการตลาด บจก. ธนารักษ์พัฒนาสินทรัพย์"
      ) {
        nameFile = "";
      } else if (this.form.from == "major-minor") {
        nameFile = "credit-type.xlsx";
      } else if (this.form.from == "product-program") {
        nameFile = "product-program.xlsx";
      } else if (this.form.from == "insurance-product") {
        nameFile = "insurance-type.xlsx";
      }
      var file_path = this.urlBase + "crm/download/template/" + nameFile;
      console.log(file_path);
      var a = document.createElement("A");
      a.href = file_path;
      a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      this.$message({
        message: "Download Template ข้อมูลสำเร็จ",
        type: "success",
        duration: 4000,
      });
      setTimeout(() => {
        this.loadingSubmit = false;
      }, 200);
    },
    async submitExportData() {
      let res = await this.apiExport();
      if (res.data.success) {
        var file_path = this.urlBase + "crm/download/" + res.data.obj;
        var a = document.createElement("A");
        a.href = file_path;
        a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.$message({
          message: "Export ข้อมูลสำเร็จ",
          type: "success",
          duration: 4000,
        });
      }
    },
  },
};
</script>
