<template>
  <div>
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow"
      :data="dataDepartment"
      border
      style="width: 100%"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
      </template>
      <el-table-column
        prop="juristicId"
        label="เลขทะเบียนนิติบุคคล (13 หลัก)"
        min-width="200px"
      >
      </el-table-column>

      <el-table-column
        prop="companyNameTH"
        label="ชื่อผู้ประกอบการ"
        min-width="210px"
      >
      </el-table-column>
      <el-table-column prop="status" label="สถานภาพ" min-width="150px">
      </el-table-column>
      <el-table-column prop="size" label="Size สสว." min-width="100px">
      </el-table-column>
      <el-table-column label="สถานะผู้ส่งออก-นำเข้า">
        <el-table-column prop="exporter" label="Exporter" min-width="150px">
        </el-table-column>
        <el-table-column prop="importer" label="Importer" min-width="150px">
        </el-table-column>
      </el-table-column>
      <el-table-column label="มูลค่าการส่งออก">
        <template slot="header">
          <span
            >มูลค่าการส่งออก <br />
            (ล้านบาท)
          </span>
        </template>
        <el-table-column prop="importOrExport" label="2564" min-width="120px">
          <template slot-scope="scope">
            {{ mapYearAndType(scope.row.importOrExport, "export", "2564") }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="มูลค่าการนำเข้า">
        <template slot="header">
          <span
            >มูลค่าการนำเข้า <br />
            (ล้านบาท)
          </span>
        </template>
        <el-table-column prop="importOrExport" label="2564" min-width="120px">
          <template slot-scope="scope">
            {{ mapYearAndType(scope.row.importOrExport, "import", "2564") }}
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column label="รายได้จากการดำเนินงาน">
        <template slot="header">
          <span
            >รายได้จากการดำเนินงาน <br />
            (ล้านบาท)
          </span>
        </template>
        <el-table-column prop="importOrExport" label="2564" min-width="120px">
          <template slot-scope="scope">
            {{ mapYearAndType(scope.row.importOrExport, "income", "2564") }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="สัดส่วนการส่งออกต่อรายได้จากการดำเนินการ (%)">
        <el-table-column prop="importOrExport" label="2562" min-width="120px">
          <template slot-scope="scope">
            {{
              mapYearAndType(
                scope.row.importOrExport,
                "incomeRatio",
                "2562",
                "percent"
              )
            }}
          </template>
        </el-table-column>
        <el-table-column prop="importOrExport" label="2563" min-width="120px">
          <template slot-scope="scope">
            {{
              mapYearAndType(
                scope.row.importOrExport,
                "incomeRatio",
                "2563",
                "percent"
              )
            }}
          </template>
        </el-table-column>
        <el-table-column prop="importOrExport" label="2564" min-width="120px">
          <template slot-scope="scope">
            {{
              mapYearAndType(
                scope.row.importOrExport,
                "incomeRatio",
                "2564",
                "percent"
              )
            }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>

    <div class="text-center mg-t-5">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="changePage"
        :current-page.sync="page"
        :page-sizes="[10, 30, 50, 100, 500]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNow"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataDepartment: {
      type: Array,
      default() {
        return [];
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    totalNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    limit: {
      type: Number,
      default() {
        return 10;
      },
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.page = this.pageNow;
  },

  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    handleSizeChange(page) {
      this.$emit("handleSizeChange", page);
    },
    mapYearAndType(arr, type, year, unit) {
      let result = "";
      if (arr.length > 0) {
        var find = arr.find((e) => e.type == type && e.year == year);
        if (typeof find != "undefined" && find.value > 0) {
          if (unit == "percent") {
            result = find.value * 100;
            result = result.toFixed(0) + "%";
          } else {
            result = find.value / 1000000;
            result = result.toFixed(2);
          }
        } else {
          result = "";
        }
      }
      return result;
    },
  },
};
</script>
