<template>
  <div>
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow"
      :data="dataAppeove"
      border
      style="width: 100%"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
      </template>
      <el-table-column prop="leadId" label="leadRefId" width="85px">
      </el-table-column>
      <el-table-column prop="customerId" label="custCode" width="85px">
      </el-table-column>
      <el-table-column
        prop="juristicId"
        label="juristicOrPersonId"
        width="140px"
      >
      </el-table-column>
      <el-table-column prop="custNameTH" label="custNameTH" min-width="150px">
      </el-table-column>
      <el-table-column prop="custNameEN" label="custNameEN" min-width="150px">
      </el-table-column>
      <el-table-column prop="leadStatusCode" label="leadStatusCode" width="90px">
      </el-table-column>
      <el-table-column
        prop="leadStatus"
        label="leadStatus"
       width="100px"
      >
      </el-table-column>
      <el-table-column
        prop="policyService"
        label="policyService"
        width="150px"
      >
      </el-table-column>
      <el-table-column
        prop="pipelineStatus"
        label="policyStatus"
        width="150px"
      >
      </el-table-column>
      <el-table-column
        prop="done"
        label="actionDetails"
        width="170px"
      >
      </el-table-column>
      <el-table-column
        prop="reasonNotInterest"
        label="denyReason"
        width="150px"
      >
      </el-table-column>
      
      <el-table-column
        prop="denyReasonOther"
        label="denyReasonOther"
        width="100px"
      >
      </el-table-column>
      
      <el-table-column
        prop="pipelineGuid"
        label="pipelineGuid"
        width="170px"
      >
      </el-table-column>
      
      
    </el-table>

    <div class="text-center mg-t-5">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="changePage"
        :current-page.sync="page"
        :page-sizes="[10, 30, 50, 100, 500]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNow"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataAppeove: {
      type: Array,
      default() {
        return [];
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    totalNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    limit: {
      type: Number,
      default() {
        return 10;
      },
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.page = this.pageNow;
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    handleSizeChange(page) {
      this.$emit("handleSizeChange", page);
    },
  },
};
</script>

<style></style>
